import React, {Component} from 'react';
import {Route} from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import {BondsGrid} from './components/bonds/bondsGrid';
import {BondDetails} from './components/bonds/bondDetails';
import {TokenDetails} from './components/tokens/tokenDetails';
import {AllBondsGrid} from './components/bonds/allBondsGrid';
import {TokensGrid} from './components/tokens/alltokens';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import EventsBrowser from './components/events/eventsBrowser';
import './styles.css';


export default class App extends Component {
    displayName = App.name

    render() {
        const theme = createMuiTheme({
            typography: {
                body2: {
                    fontSize: '0.75rem'
                }
            }
        });

        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Route exact path='/' component={Home}/>
                    <Route path='/bonds/' exact component={BondsGrid}/>
                    <Route path='/bonds/:bondType' component={BondsGrid}/>
                    <Route path='/bond/:id/:tab?' component={BondDetails}/>
                    <Route path='/token/:id' component={TokenDetails}/>
                    <Route path='/eventsBrowser/' component={EventsBrowser}/>
                    <Route path='/allbonds/' component={AllBondsGrid}/>
                    <Route path='/alltokens/' component={TokensGrid}/>
                </Layout>
            </ThemeProvider>
        );
    }
}
