import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FormattedDate} from '../../common/formattedDate';

export class PriceDetails extends Component {
    displayName = PriceDetails.name

    constructor(props) {
        super(props);

        this.state = {
            priceItems: props.priceItems
        };
    }

    render() {
        let priceItems = this.state.priceItems;
        return (<Table aria-label="simple table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Стоимость</TableCell>
                    <TableCell>Купон</TableCell>
                    <TableCell>Выплата</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {priceItems.map(item =>
                    <TableRow key={item.date}>
                        <TableCell>
                            <FormattedDate dateToShow={item.date}/>
                        </TableCell>
                        <TableCell>{this.price(item)}</TableCell>
                        <TableCell>{this.coupon(item)}</TableCell>
                        <TableCell>{this.payment(item)}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>);
    }

    price(item) {
        if (item.priceItemInAltCurrency === undefined || item.priceItemInAltCurrency.price === 0) {
            return `${item.price} ${item.currencyText}`;
        } else {
            return `${item.price} ${item.currencyText} (${item.priceItemInAltCurrency.price} ${item.priceItemInAltCurrency.currencyText})`;
        }
    }

    coupon(item) {
        if (item.coupon != null) {
            if (item.priceItemInAltCurrency === undefined || item.priceItemInAltCurrency.coupon === 0) {
                return `${item.coupon} ${item.currencyText}`;
            } else {
                return `${item.coupon} ${item.currencyText} (${item.priceItemInAltCurrency.coupon} ${item.priceItemInAltCurrency.currencyText})`;
            }
        }

        return `0 ${item.currencyText}`;
    }

    payment(item) {
        if (item.payment != null) {
            if (item.priceItemInAltCurrency === undefined || item.priceItemInAltCurrency.payment === 0) {
                return `${item.payment} ${item.currencyText}`;
            } else {
                return `${item.payment} ${item.currencyText} (${item.priceItemInAltCurrency.payment} ${item.priceItemInAltCurrency.currencyText})`;
            }
        }

        return `0 ${item.currencyText}`;
    }
}