import React, {Component} from 'react';

export class FormattedDate extends Component {
    displayName = FormattedDate.name

    pad = (number) => {
        return (number < 10) ? '0' + number : number;
    }

    render() {
        let result = '-';
        if (this.props.dateToShow !== null) {
            let dateToShow = new Date(this.props.dateToShow);
            result = [this.pad(dateToShow.getDate()), this.pad(dateToShow.getMonth() + 1), dateToShow.getFullYear()].join('.');
        }

        return (<span>{result}</span>);
    }
}