import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {EmissionDetails} from './emissionDetails';
import {PaymentDetails} from './paymentDetails';
import {PriceDetails} from './priceDetails';
import EarlyRedemptionDetails from './earlyRedepmtionDetails';
import EmissionDocuments from './emissionDocuments';
import TabPanel from './../../common/tabPanel';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function getTabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CouponBondDetails(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState('emission-details');
    const {bond} = props;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab value='emission-details' label="Эмиссия" {...getTabProps('emission-details')} />
                    {bond.paymentItems != null && bond.paymentItems.length > 0 &&
                        <Tab value='payments' label="Выплаты" {...getTabProps('payments')} />}
                    {bond.earlyRedemptionItems != null && bond.earlyRedemptionItems.length > 0 &&
                        <Tab value='earlyRedemption' label="Оферта" {...getTabProps('earlyRedemption')} />}
                    <Tab value='price' label="Стоимость" {...getTabProps('price')} />
                    {bond.documents != null &&
                        <Tab value='documents' label="Документы" {...getTabProps('documents')} />}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index="emission-details">
                <EmissionDetails bond={bond}/>
            </TabPanel>
            <TabPanel value={value} index="payments">
                <PaymentDetails paymentItems={bond.paymentItems}/>
            </TabPanel>
            <TabPanel value={value} index="earlyRedemption">
                <EarlyRedemptionDetails earlyRedemptionItems={bond.earlyRedemptionItems}/>
            </TabPanel>
            <TabPanel value={value} index="price">
                <PriceDetails priceItems={bond.priceItems}/>
            </TabPanel>
            <TabPanel value={value} index="documents">
                <EmissionDocuments documents={bond.documents}/>
            </TabPanel>
        </div>
    );
}