import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {NearestEvents} from './events/nearestEvents';
import LatestEmissions from './latestEmissions';
import Typography from '@material-ui/core/Typography';
import {BondsGrid} from './bonds/bondsGrid';
import {Paper} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    paper: {
        height: '100%',
        width: '100%'
    },
    typography: {
        marginBottom: "5px",
        paddingLeft: "6px"
    },
    allEventsTypography: {
        paddingRight: "6px"
    },
    bondsGrid: {
        marginTop: "16px"
    }
})

class Home extends Component {
    displayName = Home.name

    render() {
        const {classes} = this.props;
        return (<Grid container alignItems="stretch">
            <Grid item xs={12}>
                <Grid container direction="row" spacing={2} justify="space-between" wrap="wrap" alignItems="stretch">
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <Grid container justify="space-between">
                                <Typography className={classes.typography} variant="h5">Ближайшие события</Typography>
                                <Typography className={classes.allEventsTypography} variant="h5"><Link
                                    to={'/eventsBrowser/'}>Все события</Link></Typography>
                            </Grid>
                            <NearestEvents/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.typography} variant="h5">Новые эмиссии</Typography>
                            <LatestEmissions/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.bondsGrid} xs={12}>
                <BondsGrid/>
            </Grid></Grid>);
    }
}

export default withStyles(styles)(Home);
