import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import DiscountBondDetails from './details/discountBondDetails';
import CouponBondDetails from './details/couponBondDetails';
import IndexedCouponBondDetails from './details/indexedCouponBondDetails';
import bondsService from '../services/bondsService';

class BondDetails extends Component {
    displayName = BondDetails.name

    constructor(props) {
        super(props);
        this.state = {
            bond: {}, loading: true, selectedTab: ''
        };

        bondsService.getBondDetails(props.match.params.id)
            .then(data => {
                this.setState({bond: data, loading: false});
            });
        this.state.selectedTab = props.match.params.tab !== undefined ? props.match.params.tab : '';
    }

    renderBondDetails(bond, selectedTab) {
        var bondPaymentType = bond.paymentType;

        if (bondPaymentType === 1) {
            return (<Paper><DiscountBondDetails bond={bond} selectedTab={selectedTab}/></Paper>);
        } else if (bondPaymentType === 2) {
            return (<Paper><CouponBondDetails bond={bond} selectedTab={selectedTab}/></Paper>);
        } else if (bondPaymentType === 3) {
            return (<Paper><IndexedCouponBondDetails bond={bond} selectedTab={selectedTab}/></Paper>);
        }

        return <Paper>Something went wrong.</Paper>
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderBondDetails(this.state.bond, this.state.selectedTab);

        return (contents);
    }
}

export {BondDetails}