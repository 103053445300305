import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {FormattedDate} from '../../common/formattedDate';

const styles = theme => ({
    Cell: {
        paddingRight: "8px;"
    }
});

class EarlyRedemptionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            earlyRedemptionItems: props.earlyRedemptionItems
        };
    }

    render() {
        const {classes} = this.props;
        if (this.state.earlyRedemptionItems === null) {
            return (<div></div>);
        }

        return (<Table aria-label="simple table" size="small" className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Детали</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.state.earlyRedemptionItems.map(item =>
                    <TableRow key={item.date}>
                        <TableCell>
                            <FormattedDate dateToShow={item.date}/>
                        </TableCell>
                        <TableCell>{item.details}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>);
    }
}

export default withStyles(styles, {withTheme: true})(EarlyRedemptionDetails);