export function numberFormatter(num) {
    if (num >= 1000000000) {
        return (num / 1000000000).toString().replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toString().replace(/\.000$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toString().replace(/\.0$/, '') + 'K';
    }
    return num;
}