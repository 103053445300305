import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {debounceSearchRender} from "../common/debounceSearchRenderer";
import {numberFormatter} from "../../utils/numberFormatter";
import {FormattedDate} from "./../common/formattedDate";
import bondsService from "./../services/bondsService";

export class AllBondsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            count: 0,
            rowsPerPage: 20,
            sortOrder: {},
            data: [],
            columns: [
                {
                    name: "name",
                    label: "Эмитент, номер выпуска",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <Link className="bondLink"
                                  to={`/bond/${value.registrationId}`}>{value.emiter} {value.number}</Link>
                        )
                    }
                },
                {
                    name: "volume",
                    label: "Объем",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (numberFormatter(value.volume) + ' ' + value.currency)
                    }
                },
                {
                    name: "percent",
                    label: "Доходность %",
                    options: {
                        customBodyRenderer: (value, tableMeta, updateValue) => (<Typography>{value + '%'}</Typography>)
                    }
                },
                {
                    name: "nominalPrice",
                    label: "Номинальная цена",
                    options: {
                        customBodyRender: (value, tableMeta, updateValu) => (value.amount + ' ' + value.currencyText)
                    }
                },
                {
                    name: "startDate",
                    label: "Обращение с",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (<FormattedDate dateToShow={value}/>)
                    }
                },
                {
                    name: "endDate",
                    label: "по",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (<FormattedDate dateToShow={value}/>)
                    }
                }
            ],
            isLoading: false
        };
    }

    componentDidMount() {
        this.getData('', 0, this.state.rowsPerPage);
    }

    getData = (searchText, pageNumber, rowsPerPage) => {
        this.setState({isLoading: true});
        bondsService.getAllBonds(searchText, pageNumber, rowsPerPage).then(res => {
            this.setState({
                isLoading: false,
                page: res.page,
                data: res.data,
                count: res.total,
                searchText: res.searchText
            });
        });
    }

    changePage = (page, searchText) => {
        this.getData(searchText, page, this.state.rowsPerPage);
    };

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    padding: "8px",
                    "line-height": 1.1,
                    "font-size": "0.75rem"
                }
            },
            MuiTypography: {
                "body1": {
                    "font-size": "0.725rem",
                }
            }
        }
    })

    render() {

        const {data, count, rowsPerPage, sortOrder} = this.state;

        const options = {
            filter: false,
            selectableRows: 'none',
            searchOpen: true,
            viewColumns: false,
            download: false,
            print: false,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: true,
            count: count,
            customSearchRender: debounceSearchRender(1000),
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [20],
            sortOrder: sortOrder,
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                    case 'sort':
                    case 'search':
                        this.changePage(tableState.page, tableState.searchText);
                        break;
                    default:
                }
            }
        };

        return (
            <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable data={data} columns={this.state.columns} options={options}/>
            </MuiThemeProvider>
        );

    }
}