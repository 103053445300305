import React, {Component} from "react";
import {EventList} from "./EventList";
import bondEventsService from "../services/bondEventsService";


export class NearestEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({isLoading: true});
        bondEventsService.getNearestEvents(5).then(res => {
            this.setState({data: res, isLoading: false, count: res.total});
        });
    }

    render() {
        const {data} = this.state;
        if (data !== undefined) {
            return (<EventList data={data}/>);
        } else {
            return (<div></div>);
        }

    }
}