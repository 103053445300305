import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {NavMenu} from './NavMenu';

const styles = theme => ({
    RootGird: {},
    MainGrid: {
        marginTop: 20,
        marginBottom: 20
    },
    Footer: {
        height: "30px",
        backgroundColor: "#3f51b5",
        position: "relative",
        left: 0,
        bottom: 0

    }
});

class Layout extends Component {
    displayName = Layout.name;

    render() {
        const {classes} = this.props;
        return (
            <Grid container justify="center" className={classes.RootGird}>
                <Grid item sm={12}>
                    <NavMenu></NavMenu>
                </Grid>
                <Grid item sm={12} md={9} className={classes.MainGrid}>
                    {this.props.children}
                </Grid>

                {/* <Grid item sm={12} md={12} className={classes.Footer}>
                </Grid>  */}
            </Grid>
        );
    }
}

export default withStyles(styles, {withTheme: true})(Layout);