import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FormattedDate} from '../../common/formattedDate';

export class PaymentDetails extends Component {
    displayName = PaymentDetails.name

    constructor(props) {
        super(props);
        this.state = {
            paymentItems: props.paymentItems
        };
    }

    render() {
        let paymentItems = this.state.paymentItems;
        return (<Table aria-label="simple table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Выплата</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {paymentItems.map(item =>
                    <TableRow key={item.date}>
                        <TableCell>
                            <FormattedDate dateToShow={item.date}/>
                        </TableCell>

                        <TableCell>{this.paymentAmount(item)}</TableCell>
                    </TableRow>
                )}

            </TableBody>
        </Table>);
    }

    paymentAmount(item) {
        if (item.amount === null) {
            return ``;
        }
        if (item.paymentInAltCurrency === undefined || item.paymentInAltCurrency.amount === 0) {
            return `${item.amount} ${item.currencyText}`;
        } else {
            return `${item.amount} ${item.currencyText} (${item.paymentInAltCurrency.amount} ${item.paymentInAltCurrency.currencyText})`;
        }
    }
}