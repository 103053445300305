import React, {Component} from 'react';
import {Link} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export class EmissionDocuments extends Component {
    displayName = EmissionDocuments.name

    constructor(props) {
        super(props);
        this.state = {
            documents: props.documents
        };
    }

    render() {
        if (this.state.documents === null) {
            return (<div></div>);
        }

        return (<Grid container>
            {this.state.documents.map(document =>
                <Grid item xs={12} key={document.url}>
                    <Link href={document.url}>{document.title}</Link>
                </Grid>)}
        </Grid>)
    }
}