import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {EventList} from "./EventList";
import bondEventsService from "../services/bondEventsService";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    calendar: {
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingBottom: "6px"
    }
})

class EventsBrowser extends Component {
    constructor(props) {
        super(props);

        this.state = {date: new Date(), myVal: 'test'};
        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({isLoading: true});
        bondEventsService.getMonthEvents(this.state.date).then(res => {
            this.setState({data: res, isLoading: false, count: res.total});
        });
    }

    goBack(event) {
        event.preventDefault();
        const backDate = this.state.date;
        backDate.setMonth(this.state.date.getMonth() - 1);
        this.setState({date: backDate});
        this.getData();
    }

    goForward(event) {
        event.preventDefault();
        const forwardDate = this.state.date;
        forwardDate.setMonth(this.state.date.getMonth() + 1);
        this.setState({date: forwardDate});
        this.getData();
    }

    render() {
        //new URLSearchParams(props.location.search).get("test")
        var monthName = this.state.date.toLocaleString('ru-Ru', {month: 'long'});
        var month = monthName[0].toUpperCase() + monthName.slice(1);
        var year = this.state.date.getFullYear();
        const {classes} = this.props;

        const {data} = this.state;
        return (<Paper><Grid container direction="column">
            <Grid item container direction="row" justify="space-between" className={classes.calendar}>
                <Grid item><Typography><a href='/#' onClick={this.goBack}>Назад</a></Typography></Grid>
                <Grid item><Typography>События {month} {year}</Typography></Grid>
                <Grid item><Typography><a href='/#' onClick={this.goForward}>Вперед</a></Typography></Grid>
            </Grid>
            <Grid item>
                {data !== undefined ? (<EventList data={data}/>)
                    : (<div></div>)}
            </Grid>
        </Grid>
        </Paper>);
    }
}

export default withStyles(styles)(EventsBrowser);