import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import {FormattedDate} from "../common/formattedDate";
import TableCell from '@material-ui/core/TableCell';
import {Link} from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import {Chip} from "@material-ui/core";


export class EventList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: props.data,
            sortOrder: {},
            data: [],
            columns: [
                {
                    name: "date",
                    label: "Дата",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <FormattedDate dateToShow={value}/>
                        )
                    }
                },
                {
                    name: "emissionInfo",
                    label: "Эмитент, № выпуска",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <Link className="bondLink"
                                  to={`/${value.securitiesType}/${value.id}`}>{value.name} {value.number}</Link>
                        )
                    }
                },
                {
                    name: "emissionInfo",
                    label: "Тип ЦБ",
                    options: {
                        customBodyRender: (value) => (
                            <Chip variant="outlined" size="small"
                                  label={value.securitiesType === 'bond' ? 'Облигация' : 'Токен'}/>
                        )
                    }
                },
                {
                    name: "type",
                    label: "Событие",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (this.renderEventType(value))
                    }
                },
                {
                    label: "Детали",
                    name: "payload",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (this.renderPaymentEventBody(value))
                    }
                }
            ],
            isLoading: false
        };
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyRow: {
                root: {
                    height: "40px"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    "paddingLeft": "4px",
                    "paddingRight": "4px",
                    "line-height": 1.1,
                    "font-size": "0.75rem"
                }
            },
            MuiTypography: {
                "body1": {
                    "font-size": "0.725rem",
                }
            }
        }
    })

    componentDidUpdate(props) {
        if (this.props.data !== props.data) {
            this.updateAndNotify();
        }
    }

    updateAndNotify = () => {
        this.setState({events: this.props.data});
    };

    render() {
        const {events: data} = this.state;

        const {sortOrder} = this.state;

        const options = {
            filter: false,
            selectableRows: 'none',
            searchOpen: false,
            search: false,
            viewColumns: false,
            download: false,
            print: false,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: false,
            pagination: false,
            setTableProps: () => {
                return {
                    padding: 'default',
                    // material ui v4 only
                    size: 'small'
                };
            },
            sortOrder: sortOrder,
        };

        if (data !== undefined) {
            return (<Grid>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable data={data} columns={this.state.columns} options={options}/>
                </MuiThemeProvider>
            </Grid>);
        } else {
            return (<div></div>);
        }
    }

    renderEventRow(bondEvent) {
        return <TableRow>
            <TableCell><Typography variant="body2"><FormattedDate dateToShow={bondEvent.date}/></Typography></TableCell>
            <TableCell><Typography variant="body2"><Link className="bondLink"
                                                         to={`/bond/${bondEvent.emissionInfo.registrationId}`}>{bondEvent.emissionInfo.emiter} {bondEvent.emissionInfo.number}</Link></Typography></TableCell>
            <TableCell><Typography variant="body2">{this.renderEventType(bondEvent.type)}</Typography></TableCell>
            <TableCell><Typography
                variant="body2">{bondEvent.type === 'Payment' ? this.renderPaymentEventBody(bondEvent.payload) : ''}</Typography></TableCell>
        </TableRow>;
    }

    renderPaymentEventBody(payload) {
        if (payload != null) {
            if (payload.paymentInAltCurrency === undefined) {
                return payload.amount + ' ' + payload.currencyText;
            } else {
                if (payload.amount === null) {
                    return '';
                }

                return payload.amount + ' ' + payload.currencyText +
                    ' (' + payload.paymentInAltCurrency.amount + ' ' +
                    payload.paymentInAltCurrency.currencyText + ')';

            }
        }
    }

    renderEventType(eventType) {
        switch (eventType) {
            case 'EarlyRedemption':
                return 'Досрочный выкуп';
            case 'Payment':
                return 'Выплата';
            case 'EmissionStart':
                return 'Начало обращения';
            case 'EmissionEnd':
                return 'Окончание обращения';
            default:
                return '';
        }
    }
}
