import React, {Component} from 'react';
import {Link, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {FormattedDate} from '../../common/formattedDate';

class EmissionDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: props.documents
        };
    }

    render() {
        if (this.state.documents === null) {
            return (<div></div>);
        }

        return (<Grid container>
            {this.state.documents.map(document =>
                <Grid item xs={12} key={document.url}>
                    <Typography component={'span'}>
                        <FormattedDate dateToShow={document.date}/>
                    </Typography>{" "}
                    <Link href={document.url}>{document.title}</Link>
                </Grid>)}
        </Grid>)
    }
}

export default (EmissionDocuments);