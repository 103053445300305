import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FormattedDate} from '../../common/formattedDate';

export class PaymentDetails extends Component {
    displayName = PaymentDetails.name

    constructor(props) {
        super(props);
        this.state = {
            token: props.token
        };
    }

    render() {
        let paymentItems = this.state.token.paymentItems;
        return (<Table aria-label="simple table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Выплата</TableCell>
                    <TableCell>За период</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {paymentItems.map(item =>
                    <TableRow key={item.date}>
                        <TableCell>
                            <FormattedDate dateToShow={item.date}/>
                        </TableCell>

                        <TableCell>{this.paymentAmount(item)}</TableCell>
                        <TableCell>
                            <FormattedDate dateToShow={item.paymentIntervalStartDate}/>
                            <span> - </span>
                            <FormattedDate dateToShow={item.paymentIntervalEndDate}/>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>);
    }

    paymentAmount(item) {
        return `${item.amount} ${item.currencyText}`;
    }
}