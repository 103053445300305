import React, {Component} from 'react';
import {AppBar, Toolbar} from '@material-ui/core';
import SidebarMenu from './sidebarMenu';


export class NavMenu extends Component {
    displayName = NavMenu.name

    render() {
        return (
            <AppBar position="static">
                <Toolbar>
                    <SidebarMenu/>
                </Toolbar>
            </AppBar>
        );
    }
}
