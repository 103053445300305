import React, {Component} from "react"
import Grid from '@material-ui/core/Grid';
import {FormattedDate} from "./common/formattedDate";
import MUIDataTable from "mui-datatables";
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom';
import {numberFormatter} from "../utils/numberFormatter";

const styles = theme => ({
    Cell: {
        paddingRight: '8px'
    }
});

class LatestEmissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortOrder: {},
            columns: [
                {
                    name: "name",
                    label: "Эмитент, № выпуска",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <Link className="bondLink"
                                  to={`/bond/${value.registrationId}`}>{value.emiter} {value.number}</Link>
                        )
                    }
                },
                {
                    name: "volume",
                    label: "Объем",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return numberFormatter(value.volume) + " " + value.currency;
                        }
                    }
                },
                {
                    name: "percent",
                    label: "Доходность",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return value + "%";
                        }
                    }
                },
                {
                    name: "emissionDates",
                    label: "Обращение",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <div><FormattedDate dateToShow={value.startDate}/> - <FormattedDate
                                dateToShow={value.endDate}/></div>)
                    }
                }
            ],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({isLoading: true});
        this.xhrRequest().then(res => {
            this.setState({data: res, isLoading: false, count: res.total});
        });
    }

    xhrRequest = () => {
        return fetch('api/bonds/latestemissions')
            .then(response => response.json());
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyRow: {
                root: {
                    height: "40px"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    "paddingLeft": "4px",
                    "paddingRight": "4px",
                    "line-height": 1.1,
                    "font-size": "0.75rem"
                }
            },
            MuiTypography: {
                "body1": {
                    "font-size": "0.725rem",
                }
            }
        }
    })

    render() {
        const {data} = this.state;
        const {sortOrder} = this.state;
        const options = {
            filter: false,
            selectableRows: 'none',
            searchOpen: false,
            search: false,
            viewColumns: false,
            download: false,
            print: false,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: false,
            pagination: false,
            setTableProps: () => {
                return {
                    padding: 'default',
                    // material ui v4 only
                    size: 'small'
                };
            },
            sortOrder: sortOrder,
        };
        if (data !== undefined) {
            return (<Grid>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable data={data} columns={this.state.columns} options={options}/>
                </MuiThemeProvider>
            </Grid>);
        } else {
            return (<div></div>);
        }

    }
}

export default withStyles(styles, {withTheme: true})(LatestEmissions);

