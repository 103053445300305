class BondsService {

    static getBondDetails(bondId) {
        return fetch('api/bonds/' + bondId)
            .then(response => response.json());
    }

    static getActiveBonds(bondType, searchText, pageNumber, itemsOnPage) {
        var bondTypeQuery = '';
        if (!(bondType === undefined)) {
            bondTypeQuery = 'bondType=' + bondType;
        }
        if (searchText === null) {
            searchText = '';
        }

        let query = '?' + bondTypeQuery +
            '&pageNumber=' + pageNumber +
            '&itemsOnPage=' + itemsOnPage +
            '&searchText=' + searchText;

        return fetch('api/bonds/GetBondsPaged' + query)
            .then(response => response.json());
    }

    static getAllBonds(searchText, pageNumber, itemsOnPage) {
        if (searchText === null) {
            searchText = '';
        }

        let query = '?pageNumber=' + pageNumber + '&itemsOnPage=' + itemsOnPage + '&searchText=' + searchText;

        return fetch('api/bonds/getallbondspaged' + query)
            .then(response => response.json());
    }
}

export default BondsService;