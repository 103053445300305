import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FormattedDate} from '../../common/formattedDate';

export class YieldToMaternityDetails extends Component {
    displayName = YieldToMaternityDetails.name

    constructor(props) {
        super(props);
        this.state = {
            yieldToMaternityItems: props.yieldToMaternityItems,
            nominalPriceCurrency: props.nominalPriceCurrency
        };
    }

    render() {
        if (this.state.yieldToMaternityItems === null) {
            return (<div></div>);
        }

        return (<Table aria-label="simple table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Возврат</TableCell>
                    <TableCell>Додходность к погашению</TableCell>
                    <TableCell>Общая доходность</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.state.yieldToMaternityItems.map(item =>
                    <TableRow key={item.buyDate}>
                        <TableCell>
                            <FormattedDate dateToShow={item.buyDate}/>
                        </TableCell>
                        <TableCell>
                            {item.yieldToReturnOnBuy} {this.state.nominalPriceCurrency}
                        </TableCell>
                        <TableCell>{item.yieldToMaternityPercent} %</TableCell>
                        <TableCell>{item.totalYieldPercent} %</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>);
    }
}