import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {numberFormatter} from "../../../utils/numberFormatter";
import {FormattedDate} from '../../common/formattedDate';

export class EmissionDetails extends Component {
    displayName = EmissionDetails.name

    constructor(props) {
        super(props);
        this.state = {
            bond: props.bond
        };
    }

    render() {
        let bond = this.state.bond;
        return (<Grid container spacing={2}>
            <Grid item container xs={6}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Эмитент</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{bond.name.emiter}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Номер</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{bond.name.number}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Дата начала обращения</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            <FormattedDate dateToShow={bond.startDate}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Дата погашения</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            <FormattedDate dateToShow={bond.endDate}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>Объем эмиссии</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            component={'span'}>{numberFormatter(bond.volume.volume) + ' ' + bond.volume.currency}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>Доходность</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{bond.percent}%</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Номинальная цена</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            {bond.nominalPrice.amount + ' ' + bond.nominalPrice.currencyText}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>Валюта расчетов</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{bond.paymentCurrency}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>);
    }
}