import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import {withRouter} from 'react-router'
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles({
    list: {
        width: 250,
    },
    SelectedItem: {
        paddingTop: '2px',
        paddingBottom: '2px',
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    Item: {
        paddingTop: '2px',
        paddingBottom: '2px'
    }
});

function SidebarMenu(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        isOpen: false,
    });

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({isOpen: open});
    };

    const currentLocation = props.history.location.pathname;

    return (
        <div>
            <React.Fragment>
                <Button onClick={toggleDrawer(true)}><MenuIcon/> </Button>
                <Drawer anchor='left' open={state.isOpen} onClose={toggleDrawer(false)}>
                    <div
                        className={classes.list}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}>
                        <List>
                            <ListItem button component="a" href={'/'}
                                      className={currentLocation === '/' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Главная"/>
                            </ListItem>
                        </List>
                        <Divider/>
                        <ListItem button component="a" href={'/eventsBrowser/'}
                                  className={currentLocation === '/bonds/' ? classes.SelectedItem : classes.Item}>
                            <ListItemText primary="Календарь"/>
                        </ListItem>
                        <Divider/>
                        <List>
                            <ListItem button component="a" href={'/bonds/'}
                                      className={currentLocation === '/bonds/' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Облигации в обращении"/>
                            </ListItem>
                            <ListItem button component="a" href={'/bonds/bank'}
                                      className={currentLocation === '/bonds/bank' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Банковские"/>
                            </ListItem>
                            <ListItem button component="a" href={'/bonds/government'}
                                      className={currentLocation === '/bonds/government' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Государственные"/>
                            </ListItem>
                            <ListItem button component="a" href={'/bonds/corporate'}
                                      className={currentLocation === '/bonds/corporate' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Корпоративные"/>
                            </ListItem>
                        </List>
                        <Divider/>
                        <List>
                            <ListItem button component="a" href={'/allbonds'}
                                      className={currentLocation === '/allbonds' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Все облигации"/>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem button component="a" href={'/alltokens'}
                                      className={currentLocation === '/alltokens' ? classes.SelectedItem : classes.Item}>
                                <ListItemText primary="Все токены"/>
                            </ListItem>
                        </List>
                    </div>
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default withRouter(SidebarMenu)