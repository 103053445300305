import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Typography} from "@material-ui/core";
import {numberFormatter} from "../../../utils/numberFormatter";
import {FormattedDate} from '../../common/formattedDate';

export class EmissionDetails extends Component {
    displayName = EmissionDetails.name

    constructor(props) {
        super(props);
        this.state = {
            token: props.token
        };
    }

    render() {
        let token = this.state.token;
        return (<Grid container spacing={2}>
            <Grid item container xs={6}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Эмитент</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{token.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Номер</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{token.number}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Дата начала обращения</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            <FormattedDate dateToShow={token.startDate}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Дата погашения</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            <FormattedDate dateToShow={token.endDate}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>Объем эмиссии</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            component={'span'}>{numberFormatter(token.volume.volume) + ' ' + token.volume.currency}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>Доходность</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>{token.percent}%</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'} variant="subtitle1">Номинальная цена</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            {token.nominalPrice.amount + ' ' + token.nominalPrice.currencyText}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>);
    }
}