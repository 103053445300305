import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FormattedDate} from '../../common/formattedDate';

export class EarlyRedemptionDetails extends Component {
    displayName = EarlyRedemptionDetails.name

    constructor(props) {
        super(props);
        this.state = {
            earlyRedemptionItems: props.earlyRedemptionItems
        };
    }

    render() {
        if (this.state.earlyRedemptionItems === null) {
            return (<div></div>);
        }

        return (<Table aria-label="simple table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Даты предъявления требований</TableCell>
                    <TableCell>Даты выплаты</TableCell>
                    <TableCell>Детали</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.state.earlyRedemptionItems.map(item =>
                    <TableRow key={item.requirementsStartDate}>
                        <TableCell>
                            <FormattedDate dateToShow={item.requirementsStartDate}/>
                            <span> - </span>
                            <FormattedDate dateToShow={item.requirementsEndDate}/>
                        </TableCell>
                        <TableCell>
                            <FormattedDate dateToShow={item.paymentStartDate}/>
                            <span> - </span>
                            <FormattedDate dateToShow={item.paymentEndDate}/>
                        </TableCell>
                        <TableCell>{item.details}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>);
    }
}