class TokenService {

    static getTokens(searchText, pageNumber, itemsOnPage) {
        if (searchText === null) {
            searchText = '';
        }

        let query = '?pageNumber=' + pageNumber + '&itemsOnPage=' + itemsOnPage + '&searchText=' + searchText;

        return fetch('api/tokens/alltokenspaged' + query)
            .then(response => response.json());
    }

    static getTokenDetails(tokenNumber) {
        return fetch('api/tokens/' + tokenNumber)
            .then(response => response.json());
    }
}

export default TokenService;