class BondEventsService {

    static getMonthEvents(date) {
        return fetch(`api/events/eventsByRange?date=${date.toISOString()}`)
            .then(response => response.json());
    }

    static getNearestEvents(count) {
        return fetch(`api/events/nearestEvents?count=${count}`)
            .then(response => response.json());
    }
}

export default BondEventsService;