import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import tokensService from '../services/tokenService';
import TabPanel from '../common/tabPanel';
import {EarlyRedemptionDetails} from './details/earlyRedemptionDetails';
import {EmissionDetails} from './details/emissionDetails';
import {PaymentDetails} from './details/paymentDetails';
import {EmissionDocuments} from './details/emissionDocuments';
import {YieldToMaternityDetails} from './details/yieldToMaternityDetails';

class TokenDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: {}, loading: true, selectedTab: ''
        };

        tokensService.getTokenDetails(props.match.params.id)
            .then(data => {
                this.setState({token: data, loading: false});
            });
        this.state.selectedTab = props.match.params.tab !== undefined ? props.match.params.tab : 'emission-details';
    }

    getTabProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    renderTokenDetails(token, selectedTab) {
        const handleChange = (event, newValue) => {
            this.setState((prevState) => ({
                selectedTab: newValue
            }));
        };

        return <Paper>
            <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab value='emission-details' label="Эмиссия" {...this.getTabProps('emission-details')} />
                    {token.paymentItems != null && token.paymentItems.length > 0 &&
                        <Tab value='payments' label="Выплаты" {...this.getTabProps('payments')} />}
                    {token.earlyRedemptionItems != null && token.earlyRedemptionItems.length > 0 &&
                        <Tab value='earlyRedemption' label="Оферта" {...this.getTabProps('earlyRedemption')} />}
                    {token.yieldToMaternityItems != null && token.yieldToMaternityItems.length > 0 &&
                        <Tab value='yieldToMaternity'
                             label="Расчет доходности" {...this.getTabProps("yieldToMaternity")}/>}
                    <Tab value='documents' label="Документы" {...this.getTabProps('documents')} />
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTab} index="emission-details">
                <EmissionDetails token={token}/>
            </TabPanel>
            <TabPanel value={selectedTab} index="payments">
                <PaymentDetails token={token}/>
            </TabPanel>
            <TabPanel value={selectedTab} index="earlyRedemption">
                <EarlyRedemptionDetails earlyRedemptionItems={token.earlyRedemptionItems}/>
            </TabPanel>
            <TabPanel value={selectedTab} index="yieldToMaternity">
                <YieldToMaternityDetails yieldToMaternityItems={token.yieldToMaternityItems}
                                         nominalPriceCurrency={token.nominalPrice.currencyText}/>
            </TabPanel>
            <TabPanel value={selectedTab} index="documents">
                <EmissionDocuments documents={token.documents}/>
            </TabPanel>
        </Paper>
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTokenDetails(this.state.token, this.state.selectedTab);

        return (contents);
    }
}

export {TokenDetails}